import styled from "styled-components";
import { Palette } from "../../../../theme/Palette";
import { Font } from "../../../../utilities/Constants/Font";
import { Viewport } from "../../../../utilities/Constants/Viewport";
import BackgroundTile from "../../../PageSwitch/pages/Home/AboveFold/pixel-space-tile.png";

export const SignupRoot = styled.div`
    background: ${Palette.Neutral100};
    background-image: radial-gradient(${Palette.Accent10}, transparent 40%), url(${BackgroundTile});
    background-position: 50% 100%;
    background-repeat: repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    color: ${Palette.White100};
`;

export const SignupGridLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 64px;
    background: ${Palette.White10};
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    backdrop-filter: blur(8px);
    padding: 64px;
    border-radius: 4px;

    @media screen and (max-width: ${Viewport.Large}px) {
        display: flex;
        padding: 64px 32px;
    }
`;

export const SignupInfoContainer = styled.div`
    display: grid;
    grid-template-rows: auto auto auto;
    grid-row-gap: 32px;
    text-align: left;
    max-width: 360px;

    @media screen and (max-width: ${Viewport.Large}px) {
        display: none;
    }
`;

export const FeaturesContainer = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-row-gap: 16px;
`;

export const FeatureItem = styled.div`
    display: grid;
    grid-template-columns: 20px auto;
`;

export const SignupFormContainer = styled.div`
    display: grid;
    grid-row-gap: 32px;
    box-sizing: border-box;
    border-radius: 3px;
    text-align: center;
`;

export const Heading = styled.h1`
    font-family: ${Font.SpaceGrotesk};
    font-size: 24px;
    line-height: 120%;
    font-weight: 700;
`;

export const IconContainer = styled.div`
    line-height: 24px;
`;
