import { IOrderDetails } from "@coderone/library";
import axios from "axios";
import * as React from "react";
import { useCallback, useContext, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { useSessionStorage } from "../../../../hooks/useSessionStorage";
import { Palette } from "../../../../theme/Palette";
import { Constants } from "../../../../utilities/Constants";
import { getAxiosRequestConfig } from "../../../../utilities/getAxiosRequestConfig";
import { SessionStorageKey } from "../../../../utilities/SessionStorageKey";
import { telemetry } from "../../../../utilities/Telemetry/Telemetry";
import { TelemetryEvent } from "../../../../utilities/Telemetry/TelemetryEvent";
import { AuthContext } from "../../../Auth/AuthContext";
import { WithoutAuth } from "../../../Auth/WithoutAuth";
import { Bold } from "../../../Bold/Bold";
import { Form } from "../../../Form/Form";
import { FullLogoWhite } from "../../../FullLogoWhite/FullLogoWhite";
import { RoundCheckIcon } from "../../../Icons/RoundCheckIcon";
import { CoderOneRoute } from "../../../Pages/CoderOneRoute";
import { RouterLink } from "../../../RouterLink/RouterLink";
import { SEO } from "../../../SEO/SEO";
import { Spinner } from "../../../Spinner/Spinner";
import { StateButton } from "../../../StateButton/StateButton";
import { Text } from "../../../Text/Text";
import { TextInput } from "../../../TextInput/TextInput";
import {
    IconContainer,
    FeatureItem,
    FeaturesContainer,
    SignupFormContainer,
    SignupGridLayout,
    SignupInfoContainer,
    SignupRoot,
    Heading,
} from "./Signup.styles";

interface ISignupForm {
    readonly username: string;
    readonly email: string;
    readonly password: string;
    readonly subscriptionOrderId?: string;
}
interface IPostUserResponse {
    readonly token: string;
}

const postForm = async (form: ISignupForm): Promise<IPostUserResponse> => {
    const request = axios.post(`${Constants.ApiRoot}/user`, form, getAxiosRequestConfig());

    const { data } = await request;
    return data;
};

const Signup: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { setToken } = useContext(AuthContext);
    const [t] = useTranslation();
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [submissionError, setSubmissionError] = useState<string | undefined>(undefined);
    const [orderDetails, setOrderDetails] = useSessionStorage(SessionStorageKey.PaypalOrderDetails, null);

    const onUsernameChanged = useCallback(
        (_: unknown, newValue: string | undefined) => {
            const updatedValue = newValue === undefined ? "" : newValue;
            setUsername(updatedValue);
        },
        [setEmail]
    );

    const onEmailChanged = useCallback(
        (_: unknown, newValue: string | undefined) => {
            const updatedValue = newValue === undefined ? "" : newValue;
            setEmail(updatedValue);
        },
        [setEmail]
    );

    const onPasswordChanged = useCallback(
        (_: unknown, newValue: string | undefined) => {
            const updatedValue = newValue === undefined ? "" : newValue;
            setPassword(updatedValue);
        },
        [setPassword]
    );

    const passwordErrorMessage = useMemo(() => {
        if (password.length <= 0 || password.length > 8) {
            return undefined;
        }
        return t("passwordMinLengthError");
    }, [t, password]);

    const { mutate, status } = useMutation(postForm, {
        onSuccess: (data) => {
            setToken?.(data.token);
            setOrderDetails(null);
            telemetry.Log(TelemetryEvent.SignupSuccess, null);
        },
        onError: (error, _variables, _context) => {
            const e = error as any;
            const signupError = e?.response?.data?.error;
            setSubmissionError(signupError);
            telemetry.Log(TelemetryEvent.SignupError, signupError);
        },
    });

    const isLoading = useMemo(() => {
        return status === "loading";
    }, [status]);

    const isSubmitDisabled = useMemo(() => {
        if (email.length <= 0 || password.length <= 0 || passwordErrorMessage !== undefined || isLoading) {
            return true;
        }
        return false;
    }, [email, password, passwordErrorMessage, isLoading]);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
        async (e) => {
            e.preventDefault();
            const order = typeof orderDetails === "string" ? (JSON.parse(orderDetails) as IOrderDetails) : null;
            mutate({
                username,
                email,
                password,
                ...(order !== null && { order }),
            });
            telemetry.Log(TelemetryEvent.SignupInit, null);
        },
        [username, email, password, mutate]
    );

    const createAccountHeading = orderDetails !== null ? t("signupPage.createAccountWithSubscription") : t("signupPage.createAccount");

    return (
        <WithoutAuth>
            <SEO title={t("signup")} description={t("signup")} />
            <SignupRoot>
                <SignupGridLayout>
                    <SignupInfoContainer>
                        <RouterLink href={CoderOneRoute.Home}>
                            <FullLogoWhite />
                        </RouterLink>
                        <FeaturesContainer>
                            <FeatureItem>
                                <IconContainer>
                                    <RoundCheckIcon />
                                </IconContainer>
                                <div>
                                    <Trans key="signupPage.featureOne">
                                        <Bold>Practice programming, algorithms, and machine learning</Bold> in a fun and open environment.
                                    </Trans>
                                </div>
                            </FeatureItem>
                            <FeatureItem>
                                <IconContainer>
                                    <RoundCheckIcon />
                                </IconContainer>
                                <div>
                                    <Trans key="signupPage.featureTwo">
                                        <Bold>Test your skills.</Bold> Rank up the ladder, win prizes, and get featured on exciting
                                        livestreams.
                                    </Trans>
                                </div>
                            </FeatureItem>
                            <FeatureItem>
                                <IconContainer>
                                    <RoundCheckIcon />
                                </IconContainer>
                                <div>
                                    <Trans key="signupPage.featureThree">
                                        <Bold>Join a community</Bold> exploring cutting edge technologies together.
                                    </Trans>
                                </div>
                            </FeatureItem>
                        </FeaturesContainer>
                    </SignupInfoContainer>
                    <SignupFormContainer>
                        <Heading>{createAccountHeading}</Heading>
                        <Form onSubmit={onSubmit}>
                            <TextInput placeholder={t("signupPage.username")} value={username} onChange={onUsernameChanged} readOnly={isLoading} />
                            <TextInput placeholder={t("email")} value={email} onChange={onEmailChanged} readOnly={isLoading} />
                            <TextInput
                                placeholder={t("password")}
                                type="password"
                                value={password}
                                onChange={onPasswordChanged}
                                errorMessage={passwordErrorMessage}
                                readOnly={isLoading}
                            />
                            <StateButton type="submit" disabled={isSubmitDisabled}>
                                {isLoading ? <Spinner fontSize="1em" /> : t("joinNow")}
                            </StateButton>
                            {submissionError !== undefined && <Text color={Palette.Error100}>{submissionError}</Text>}
                        </Form>
                        <p>
                            <Trans key="alreadyHaveAnAccount">
                                Already have an account? <RouterLink href={CoderOneRoute.Login}>Login</RouterLink>
                            </Trans>
                        </p>
                    </SignupFormContainer>
                </SignupGridLayout>
            </SignupRoot>
        </WithoutAuth>
    );
};

export default Signup;
