import styled from "styled-components";
import logo from "../../components/Images/logo-white.svg";

export const FullLogoWhite = styled.div`
    padding-bottom: 32px;
    background-image: url(${logo});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
    flex-grow: 0;
`;
